import {defineStore} from 'pinia'
// //1、定义容器
export const useMainStore =  defineStore('main',{
    state: ()=> {
        return {
            userId: window.localStorage.getItem("id")
        }
    },
    getters: {
        getuserstatus() {

        },
        getuser(){

        }
    },

    actions: {}
})
//2、使用容器中的state

//3、修改容器中的state

//4、容器中的action的使用