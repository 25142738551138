import { createWebHashHistory, createRouter } from 'vue-router'

import Home from '../views/home/Home.vue'

import Profile from '../views/profile/Profile.vue'

import Category from '../views/category/Category.vue'

import Cart from '../views/cart/Cart.vue'

import Login from '../views/cart/login/login.vue'

import Register from '../views/cart/register/register.vue'

import Persional from '../views/profile/personal/Personal.vue'

import Agent from '../views/profile/agent/Agent.vue'

import Detail from '../views/home/details/Details.vue'

import Layout from '../views/layout/index.vue'

import Enlist from '../views/home/details/enlist/Enlist.vue'

import CateDetail from '../views/category/details/CateDetails.vue'

import CateEnlist from '../views/category/details/enlist/CateEnlist.vue'
const router = createRouter({
    history: createWebHashHistory(),
    linkActiveClass: 'router-active',
    routes: [
        {
        path: "/",
        // redirect: '/home',
        redirect: '/login',
        // children: [
        //     { path: 'detail', component: Detail }
        // ]
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/profile',
        component: Layout,
        children: [
            { path: '/profile', redirect: '/profile/profile'},
            { path: 'profile', component: Profile },
            { path: 'personal', component: Persional },
        ]
    },
    //我的团队
    { path: '/agent', component: Agent },
    //更多页面
    { path: '/category', redirect: '/layout/category'},
    //更多详情页面
    { path: '/catedetail/:id', component: CateDetail},
    //更多登录页面
    {name: 'cateenlist', path: '/cateenlist',component: CateEnlist},
    //登录页面
    {
        path: '/cart',
        component: Layout,
        children: [
            { path: '/cart', redirect: "/cart/login" },
            { path: 'login', component: Login },
            { path: 'register', component: Register }
        ]
    },
    {
        path: '/home',
        component: Layout,
        children: [
            {  path: '/home', redirect: "/layout/home" }
            // { name: 'layouthome',path: '/home'}
        ]
    },
    // 推荐详情页面
    { path: '/detail/:id', component: Detail,},
    //报名页面
    {name: 'enlist', path: '/enlist',component: Enlist},
    {
        path: '/layout',
        component: Layout,
        children: [
            {
                path: 'home',
                component: Home
            },
            { 
                path: 'category', 
                component: Category 
            }
        ]
    },
    ]
})
export default router