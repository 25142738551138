<template>
    <vant-nav-bar-regin></vant-nav-bar-regin>
    <div class="">
        <div class="zhuce">
            <form id="form-reg" class="form-horizontal" role="form">
	<div class="text">
        <span>手机号</span>
        <input type="text" ref="phone" id="tel" @blur="blurp" placeholder="请输入手机号" class="input">
    </div>
    <div class="text">
        <span>验证码</span>
        <input type="text" placeholder="请输入验证码" class="input" style="width:55%">
        <span class="yzm"><input type="button" value="获取验证码"></span>
    </div>
    <div class="text">
        <span>昵 称</span>
        <input type="text"  ref="username" placeholder="请输入昵称" class="input">
    </div>
    <div class="text">
        <span>密 码</span>
        <input type="text" ref="password" placeholder="请输入密码" class="input">
    </div>
    </form>
    <div class="text">
        <span>确认密码</span>
        <input type="text" ref="readpassword" placeholder="请输入确认密码" class="input">
    </div>
    <div class="tip"><input type="checkbox" checked="checked">同意注册条款</div>
    <div class="btndl"><input @click="submit" type="button" value="注 册"></div>
</div>
    <div class="register">
        <img src="../../../assets/img/register/hours_03.gif" alt="">
         <span id="sj" ref="sj"></span>
    </div>
    </div>
</template>
<script >
import $ from 'jquery'
import VantNavBarRegin from '../../../components/common/vant_nvabar/VantNavBarRegin.vue'
// import {getregister} from '../../../network/index'
export default {
    name: "MyRegisert",
    data() {
        return {

        }
    },
    components: {VantNavBarRegin},
    
    methods: {
        submit() {
            //注册之前判断用户名密码手机号是否填写、
             const username = this.$refs.username.value
             const password = this.$refs.password.value
             const phone = this.$refs.phone.value
             const readpassword = this.$refs.readpassword.value
            if(username!='' && password!='' && phone!='' && readpassword!='' && readpassword!=11) {
                 getregister(username,password).then(res => {
                    alert(res)
                });
            }else {
                alert("请填写正确的信息")
            }     
        },
        blurp() {
           const str =  this.$refs.phone.value
           this.checkMobile(str);
        },
        checkMobile(str) {
            var  re = /^1\d{10}$/
            if (re.test(str)) {
              //  document.getElementById("sj").innerHTML("手机号码格式正确");
                   $("#sj").html("手机号码格式正确");
                    $("#sj").css("color","green"); 
            }
            else {
                $("#sj").html("手机号码格式错误");
                $("#sj").css("color","red"); 
            }
        }
    }
}
</script>

<style scoped>
   @import '../../../assets/css/gongyong.css';

   .register {
    margin-left: 83px;
    margin-top: -445px;
   }

   .tip {
    padding-top: 19px;
    width: 80%;
    margin: auto;
    text-align: right;
    }
    .zhuce {
    width: 100%;
    margin-top: 159px;
    }
    .register[data-v-40aee1fa] {
    margin-left: 83px;
    margin-top: -469px;
    }
    img {
    height: 114px;
    width: 200px;
    border: 0;
    margin-top: -37px;
    margin-left: 31px;
    }
    #sj{
        position: fixed;
        right: 36px;
        top: 213px;
    }
</style>