<template>
   <div>
    <router-view></router-view>
     <!-- <main-tab-bar v-show="userstatus!='user.id'"></main-tab-bar> -->
   </div>
</template>

<script >
import MainTabBar from './components/content/mainTabbar/MainTabBar.vue'
// import Login from '@/views/cart/login/login.vue'
import layout from '@/views/layout/index.vue'
export default {
    name: "MyApp",
    data() {
        return {
          userstatus:''
        }
    },
    created(){
      // userstatus=pi.getuserstatus
      //  var storage=window.localStorage;
      //  var user =storage.getItem("user");
       
      //   console.log(user.id)
    },
    mounted() {
      
    },
    components: {
    MainTabBar,
        layout
    },
    // watch:{
    //   userstatus(){

    //   }
    // },
    methods: {
      
    }
}
</script>

<style scoped>

/* 引用css格式 */
  @import './assets/css/base.css';
  
  img {
    width: 24px;
    height: 24px;
  }
 
</style>
