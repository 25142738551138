import wx from 'weixin-js-sdk'
// 引入jdk
import { getShareWx } from '../network/index'
// 引入后端接口获取 时间戳等内容

//分享到朋友圈”及“分享到QQ空间
export const commomShare = (title, link, imgurl, desc,brand) => {
    let urlPath = location.href.split("#")[0] || "";
    getShareWx(urlPath).then(res => {
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）   updateTimelineShareData
            //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）         updateAppMessageShareData
            //jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateTimelineShareData', 'updateAppMessageShareData']
            jsApiList: ['updateTimelineShareData']
        });
        wx.ready(function() {
            wx.updateTimelineShareData({
                // brand: brand,
                title:  brand, // 分享时的标题
                link:  link, // 分享时的链接 // keyi
                imgUrl: imgurl, // 分享时的图标
                desc:  title + desc,
                success: function() {
                    console.log("分享成功");
                },
                cancel: function() {
                    console.log("取消分享");
                }
            });
            
        })
    })
}

//分享给朋友”及“分享到QQ
export const commomSharefr = (title, link, imgurl, desc,brand) => {
    let urlPath = location.href.split("#")[0] || "";
    getShareWx(urlPath).then(res => {
        console.log(res)
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）   updateTimelineShareData
            //自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）         updateAppMessageShareData
            //jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateTimelineShareData', 'updateAppMessageShareData']
            jsApiList: ['updateAppMessageShareData']
        });
        wx.ready(function() {
            wx.updateAppMessageShareData({
                // title: title, // 分享时的标题
                // link: link, // 分享时的链接 // keyi
                // imgUrl: imgurl, // 分享时的图标
                // brand: brand,
                title:  brand, // 分享时的标题
                link:  link, // 分享时的链接 // keyi
                imgUrl: imgurl, // 分享时的图标
                desc:  title + desc,
                success: function() {
                    console.log("分享成功");
                },
                cancel: function() {
                    console.log("取消分享");
                }
            });
            
        })
    })
}