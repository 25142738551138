<template>
    <div class="main-tab-bar">
     <tab-bar>
       <tab-item path="/home" activeColor="red">
          <template #item-icno >
             <img  src="../../../assets/img/tabbar/home.svg" alt="">
          </template>
           <template #item-icno-active>
             <img  src="../../../assets/img/tabbar/home_active.svg" alt="">
          </template>
           <template #item-text>
              <div>推荐</div>
          </template>
        </tab-item>

         <tab-item path="/category" activeColor="red">
          <template #item-icno>
             <img  src="../../../assets/img/tabbar/category.svg" alt="">
          </template>
           <template #item-icno-active>
            <img  src="../../../assets/img/tabbar/category_active.svg" alt="">
          </template>
           <template #item-text>
              <div>更多</div>
          </template>
        </tab-item>

        <tab-item path="/profile" activeColor="red"> 
          <template #item-icno>
           <img  src="../../../assets/img/tabbar/profile.svg" alt="">
          </template>
           <template #item-icno-active>
             <img  src="../../../assets/img/tabbar/profile_active.svg" alt="">
          </template>
           <template #item-text>
              <div>我的</div>
          </template>
        </tab-item>

         <!-- <tab-item path="/cart" activeColor="red">
          <template #item-icno>
           <img  src="../../../assets/img/tabbar/shopcart.svg" alt="">
          </template>
           <template #item-icno-active>
            <img  src="../../../assets/img/tabbar/shopcart_active.svg" alt="">
          </template>
           <template #item-text>
             <div>登录</div>
          </template>
        </tab-item> -->
     </tab-bar>
    </div>
</template>
<script >
import TabItem from '../../common/tabbar/TabItem.vue'
import TabBar from '../../common/tabbar/TabBar.vue'
export default {
    name: "MyMainTabBar",
    data() {
        return {}
    },
    components: {
       TabItem,
        TabBar
    },
    methods: {}
}
</script>

<style scoped>
     .main-tab-bar img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
</style>
