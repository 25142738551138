<template>
         <div class="tab-bar-item" @click="itemclick">
              <slot v-if="!isActive" name="item-icno"></slot>
              <slot v-else name="item-icno-active"></slot>
              <div :style="styleActiuve">
                  <slot name="item-text"></slot>
              </div>
         </div>
</template>

<script >
export default {
    name: "MyTabItem",
    props: {
        activeColor : {
            type: String,
            default: 'red'
        },
        path: String
    },
    data() {
        return {
           // isActive: false
        }
    },
    computed: {
        isActive() {
            return this.$route.path.indexOf(this.path)  !==-1
        },
        styleActiuve() {
            return this.isActive ? {color: this.activeColor} : {}
        }
    },
    components: {
    },
    methods: {
        itemclick() {
           this.$router.push(this.path)
        }
    }
}
</script>

<style scoped>
    .tab-bar-item {
    text-align: center;
    font: 14px;
    flex: 1;
  }


  /* .active {
      color:red;
  } */
</style>
