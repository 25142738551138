<template>
    <div>
        <main-tab-bar v-show="userstatus!='user.id'"></main-tab-bar>
        <router-view></router-view>
    </div>
</template>
<script>
import MainTabBar from '../../components/content/mainTabbar/MainTabBar.vue'
export default {
    components:{
        MainTabBar
    }
}
</script>