<template>
     <div class="tab-bar">
     <slot></slot>
    </div>
</template>

<script >
export default {
    name: "MyTabBar",
    data() {
        return {}
    },
    components: {},
    methods: {}
}
</script>

<style>
  .tab-bar {
    display: flex;
    background-color: #f2f2f2f2;
    /* 22-04-10改 */
    z-index: 999;     
    /* 固定定位 */
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    /* box-shadow: 0px -3px 10px rgba(0,0,0,0.1); */
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
  }

 
</style>
