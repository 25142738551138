<template>
    <div class="nav-bar">
        <div class="left"><slot name="left"></slot></div>
        <div class="center"><slot name="center"></slot></div>
        <div class="right"><slot name="right"></slot></div>
    </div>
</template>

<script >
export default {
    name: "MyNavBar",
    data() {
        return {}
    },
    components: {},
    methods: {}
}
</script>

<style scoped>
    .nav-bar {
        display: flex;
        height: 44px;
         text-align: center;
        line-height: 44px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
    }
    .left,.right {
        width: 60px;
    }
    .center {
        flex: 1;
        font-size: 20px;
    }
</style>
